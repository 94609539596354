<template>
  <div class="search">
    <el-input v-model="name" class="inline-input" :placeholder="placeTitle" />
    <el-button
      @click="search"
      class="btn"
      type="primary"
      icon="el-icon-search"
    ></el-button>
  </div>
</template>

<script>
export default {
  name: "searchCompany",
  props: {
    value: {
      type: String,
    },
    placeTitle: {
      type: String,
      default: "请输入协会名称",
    },
    index: {
      type: Number,
    },
    genreType: {
      type: Number | String,
      default: "",
    },
  },
  data() {
    return {
      name: this.value,
      zhReg: /^[\u4e00-\u9fa5]+$/i,
    };
  },
  watch: {
    name(nv) {
      this.$emit("input", nv);
      if (nv === "") {
        this.$emit("pitch", "");
      }
    },
    value(nv) {
      this.name = nv;
    },
  },
  mounted() {},
  methods: {
    search() {
      this.$emit("search");
    },
  },
};
</script>

<style scoped lang="less">
.search {
  .inline-input {
    width: 300px;
    position: relative;
    right: -5px;
  }
  .btn {
    position: relative;
    z-index: 5;
    border-radius: 0 4px 4px 0;
  }
}
.name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
