<template>
  <div class="newMembercomponents">
    <div class="cd_title">最新联盟</div>
    <div v-if="affCompanyList.length <= 0 && !loading">
      <noDataImg></noDataImg>
    </div>
    <div v-loading="loading" style="min-height:200px">
      <div
        v-for="(item, index) in affCompanyList"
        :key="index"
        class="company_memeber"
        v-show="index < num"
      >
        <div class="img">
          <el-image
            style="width:58px;height:58px;"
            :src="item.logo ? item.logo : defaultLogo"
            fit="fill"
          >
          </el-image>
        </div>
        <div class="right_con">
          <span class="company cursor textOverflow" @click="toDetail(item)">{{
            item.fullname_en | priorFormat(item.fullname_zh, LOCALE)
          }}</span>
          <span class="dizhi textOverflow">{{
            item.shortname_en | priorFormat(item.shortname_zh, LOCALE)
          }}</span>
          <span class="dizhi textOverflow">{{
            item.country
              | countryCityFormat(item.region_en, item.region_zh, LOCALE)
          }}</span>
        </div>

        <!-- <el-image
            style="width: 191px; height: 97px;margin-bottom:22px"
            :src="item.logo?item.logo:defaultLogo"
            fit="corver"></el-image>
          <div class="des">
              <span v-if="item.desc_zh">{{  item.desc_zh | fullTextFormat(100) }}</span>
              <span v-else-if="item.desc_en">{{  item.desc_en | fullTextFormat(100) }}</span>
              <span v-else >-</span>
          </div>
          <div class="item_bottom cursor" @click="toDetail(item)">
              <span>查看更多</span>
              <i class="el-icon-arrow-right"></i>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newPerson",
  data() {
    return {
      num: 3,
      affCompanyList: [],
      loading: false,
      defaultLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/companyLogo.png",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let data = await this.$store.dispatch("API_index/association_alliance", {
        start: 0,
        limit: 3,
        source: this.PJSource,
      });
      this.affCompanyList = data.data;
      this.loading = false;
    },
    toDetail(item) {
      this.$router.push({
        path: "/associationDetail",
        query: {
          parameter: this._encode({
            id: item.id,
          }),
        },
      });
    },
    //点击名字跳转到个人画像
    personalPort(id) {
      let res = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: id,
          }),
        },
      });
      window.open(res.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.newMembercomponents {
  width: 100%;
  min-height: 272px;
  position: relative;
  margin-bottom: 20px;
  .cd_title {
    font-size: 16px;
    font-weight: 800;
    color: #333333;
    margin-bottom: 24px;
  }
  .company_memeber {
    display: flex;
    width: 300px;
    margin-bottom: 13px;
    padding: 5px;
    .img {
      width: 58px;
      height: 58px;
    }
    .right_con {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: 12px;
      width: 200px;
      .company {
        width: 200px;
        height: 20px;
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        line-height: 20px;
      }
      .dizhi {
        width: 200px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #909399;
        line-height: 17px;
      }
    }
  }

  .des {
    width: 242px;
    min-height: 160px;
    margin: auto;
    padding-top: 7px;
    margin-bottom: 24px;
    line-height: 20px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
    width: 242px;
    height: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #4c4c4c;
    line-height: 22px;
    text-align: left;
  }
  .item_bottom {
    margin-top: 24px;
    border-top: 1px solid #e9e9e9;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #345292;
    line-height: 20px;
    span {
      padding-right: 8px;
    }
  }
}
</style>
