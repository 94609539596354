<template>
  <div class="wrap">
    <commonPageBanner url="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/lian_men_banner.png" />
    <div class="content_area">
      <searchAssociation
        v-model="queryParams.companyName"
        @search="search"
        class="search-wrap"
      />
      <div class="main">
        <div class="left" style="overflow: auto">
          <div>
            <assItem v-for="(item, index) in companyList" :key='index' :item="item" ></assItem>
          </div>
          <isLoadingTip v-if="loading" />
          <isAllDataTip
            v-if="
              companyList.length && total === companyList.length && !loading
            "
          />
          <noDataImg v-if="companyList.length === 0"></noDataImg>
        </div>
        <div class="right">
          <newAss></newAss>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonPageBanner from "~gbk/components/layouts/pageBanner";
import searchAssociation from "~gbk/components/association/searchAssociation";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import assItem from "~gbk/components/association/assItem"
import newAss from "~gbk/components/association/newAss"
export default {
  components: {
    commonPageBanner,
    searchAssociation,
    isLoadingTip,
    isAllDataTip,
    assItem,
    newAss
  },
  metaInfo: {
    title: "协会联盟介绍",
  },
  data() {
    return {
      total: 0,
      currentPage: 0,
      companyList: [],
      loading: false,
      queryParams: {
        companyName: "",
      },
      companyName: "",
      filters: {
        city: {},
        unitType: null,
        checkYear: null,
        checkClass: [],
        checkCompaines: [],
        checkLine: [],
      },
      cityType: 0,
    };
  },
  computed: {},
  created() {},
  watch: {
    "$route.query.parameter"() {
      if (this.$route.query.parameter) {
        this.queryParams.companyName = this._decode(
          this.$route.query.parameter
        ).searchVal;
      } else {
        this.queryParams.companyName = "";
      }
      this.currentPage = 0;
      this.getList(1);
    },
  },
  mounted() {
    document.title="协会联盟介绍"
    if (this.$route.query.parameter) {
      this.queryParams.companyName = this._decode(
        this.$route.query.parameter
      ).searchVal;
    }
    this.getList(1);
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getList(type) {
      window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索赋值 2是下拉拼接
      this.loading = true;
      let params={
          start:this.currentPage,
          limit:10,
          is_top:1,
          source:this.PJSource,
          user_id:this.USER_INFO.id,
          name:this.queryParams.companyName
      }

      let res = await this.$store.dispatch(
        "API_index/association_alliance",
        params
      );
      console.log(res);
      if (res.success) {

        window.addEventListener("scroll", this.scrollEvent, false);
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        this.currentPage++;
        this.loading = false;
        if (type === 1) {
          this.companyList = res.data;
        } else {
          this.companyList = this.companyList.concat(res.data);
        }
        this.total = res.total;
      }
    },
    search() {
      this.currentPage = 0;
      this.getList(1);
    },
    handleCurrentChange() {},
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 354
      ) {
        if (this.total != this.companyList.length) {
          this.getList(2);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fafafa;
}

.content_area {
  .search-wrap {
    text-align: right;
    margin-bottom: 16px;
  }

  .main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .left {
      margin-top: 20px;
      width: 896px;
      position: relative;
    }

    .right {
      margin-top: 20px;
      width: 280px;
    }
  }
}
</style>
